import React, { useEffect, useState } from "react";

import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback, Table } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useParams, useLocation } from 'react-router-dom';
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { API_URL } from "components/Constant";
import toast from "react-hot-toast";



const Update = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [loadingupdation, setLoadingupdation] = useState(true)
    const datafetch = async () => {
        try {
            const data = new FormData();
            data.append('id', id);
            const response = await axios.post(`${API_URL}/restaurants/admin/fetch-single`, data);
            formik.setFieldValue("restaurantName", response.data.name);
            formik.setFieldValue("streetAddress", response.data.streetAddress);
            formik.setFieldValue("postNumber", response.data.postNumber);
            formik.setFieldValue("city", response.data.city);
            formik.setFieldValue("telephoneNumber", response.data.telephoneNumber);
            formik.setFieldValue("emailAddress", response.data.email);
            formik.setFieldValue("webpageLink", response.data.webpageLink);
            formik.setFieldValue("businessType", response.data.businesstype);
            formik.setFieldValue("cuisine", response.data.Cuisine);
            formik.setFieldValue("businessOffer", response.data.businessOffer);
            formik.setFieldValue("groupSize", response.data.groupSize);
            formik.setFieldValue("menuDetails", response.data.menuDetails);
            formik.setFieldValue("businessDescription", response.data.description);
            formik.setFieldValue("preprofilePicture", response.data.profilePicture);
            formik.setFieldValue("preimages", response.data.images);
            formik.setFieldValue("available", response.data.availability);
            formik.setFieldValue("type", response.data.type);
            formik.setFieldValue("subject", response.data.subject);
            formik.setFieldValue("message", response.data.message);

            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        datafetch();
    }, [id])
    const optionGroup = [
        { label: "06 - 07", value: "06 - 07" },
        { label: "07 - 08", value: "07 - 08" },
        { label: "08 - 09", value: "08 - 09" },
        { label: "09 - 10", value: "09 - 10" },
        { label: "10 - 11", value: "10 - 11" },
        { label: "11 - 12", value: "11 - 12" },
        { label: "12 - 13", value: "12 - 13" },
        { label: "13 - 14", value: "13 - 14" },
        { label: "14 - 15", value: "14 - 15" },
        { label: "15 - 16", value: "15 - 16" },
        { label: "16 - 17", value: "16 - 17" },
        { label: "17 - 18", value: "17 - 18" },
        { label: "18 - 19", value: "18 - 19" },
        { label: "19 - 20", value: "19 - 20" },
        { label: "20 - 21", value: "20 - 21" },
        { label: "21 - 22", value: "21 - 22" },
        { label: "22 - 23", value: "22 - 23" },
        { label: "23 - 24", value: "23 - 24" },
        { label: "24 - 01", value: "24 - 01" }
    ];

    const options = [
        { label: "Choose", value: "", style: { display: 'none' } },
        { label: "50% Off food", value: "50% Off food" },
        { label: "2 for 1", value: "2 for 1" },
        { label: "25% Off Total bill", value: "25% Off Total bill" },
        { label: "25% Off Lunch", value: "25% Off Lunch" },
        { label: "50% Off Takeaway Coffee", value: "50% Off Takeaway Coffee" },
        { label: "25% Off Coffee and Confectionery", value: "25% Off Coffee and Confectionery" },
        { label: "25% Off Take away Food", value: "25% Off Take away Food" },
        { label: "25% Off Catering", value: "25% Off Catering" }
    ];

    const cuisines = [
        { label: "African", value: "African" },
        { label: "American", value: "American" },
        { label: "Asian", value: "Asian" },
        { label: "Bangladeshi", value: "Bangladeshi" },
        { label: "Belgian", value: "Belgian" },
        { label: "Brazilian", value: "Brazilian" },
        { label: "British", value: "British" },
        { label: "Bulgarian", value: "Bulgarian" },
        { label: "Caribbean", value: "Caribbean" },
        { label: "Chinese", value: "Chinese" },
        { label: "Cuban", value: "Cuban" },
        { label: "English", value: "English" },
        { label: "Ethiopian", value: "Ethiopian" },
        { label: "European", value: "European" },
        { label: "French", value: "French" },
        { label: "Fusion", value: "Fusion" },
        { label: "German", value: "German" },
        { label: "Goan", value: "Goan" },
        { label: "Greek", value: "Greek" },
        { label: "Indian", value: "Indian" },
        { label: "Indonesian", value: "Indonesian" },
        { label: "International", value: "International" },
        { label: "Irish", value: "Irish" },
        { label: "Italian", value: "Italian" },
        { label: "Japanese", value: "Japanese" },
        { label: "Latin", value: "Latin" },
        { label: "Lebanese", value: "Lebanese" },
        { label: "Malaysian", value: "Malaysian" },
        { label: "Mediterranean", value: "Mediterranean" },
        { label: "Mexican", value: "Mexican" },
        { label: "Middle Eastern", value: "Middle Eastern" },
        { label: "Moroccan", value: "Moroccan" },
        { label: "Nepalese", value: "Nepalese" },
        { label: "Pakistani", value: "Pakistani" },
        { label: "Persian", value: "Persian" },
        { label: "Polish", value: "Polish" },
        { label: "Portuguese", value: "Portuguese" },
        { label: "Spanish", value: "Spanish" },
        { label: "Sri Lankan", value: "Sri Lankan" },
        { label: "Sushi", value: "Sushi" },
        { label: "Tapas", value: "Tapas" },
        { label: "Thai", value: "Thai" },
        { label: "Turkish", value: "Turkish" },
        { label: "Vegan", value: "Vegan" },
        { label: "Vietnamese", value: "Vietnamese" }
    ];

    //meta title
    document.title = "Grandsave Update Restaurants";

    const validationSchema = Yup.lazy((values) => {
        return Yup.object().shape({
            restaurantName: Yup.string().required("Restaurant name is required"),
            streetAddress: Yup.string().required("Street address is required"),
            postNumber: Yup.string().required("Post number is required"),
            city: Yup.string().required("City is required"),
            telephoneNumber: Yup.string().required("Telephone number is required"),
            emailAddress: Yup.string().email("Invalid email address").required("Email address is required"),
            webpageLink: Yup.string(),
            businessType: Yup.string().required("Business type is required"),
            cuisine: Yup.array().min(1, "At least one cuisine is required"),
            businessOffer: Yup.array().min(1, "At least one business offer is required"),
            days: Yup.string().when('validity', {
                is: true,
                then: () => Yup.string().required("Please select a day"),
            }),
            hours: Yup.array().when('validity', {
                is: true,
                then: () => Yup.array().min(1, "At least one hour range is required"),
            }),
            groupSize: Yup.string().required("Group size is required"),
            menuDetails: Yup.string().required("Menu Details are required"),
            businessDescription: Yup.string().required("Business description is required"),
        });
    })

    const formik = useFormik({
        initialValues: {
            restaurantName: "",
            streetAddress: "",
            postNumber: "",
            city: "",
            telephoneNumber: "",
            emailAddress: "",
            webpageLink: "",
            businessType: "",
            cuisine: [],
            businessOffer: [],
            days: "",
            hours: [],
            groupSize: "",
            images: [],
            preimages: [],
            menuDetails: "",
            businessDescription: "",
            profilePicture: null,
            preprofilePicture: null,
            validity: false,
            available: [],
            subject: '',
            message: '',
            type: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            console.log("Form data:", values);
            try {
                setLoadingupdation(true)
                const data = new FormData();
                data.append('id', id);
                data.append('name', values.restaurantName);
                data.append('streetAddress', values.streetAddress);
                data.append('telephoneNumber', values.telephoneNumber);
                data.append('postNumber', values.postNumber);
                data.append('city', values.city);
                data.append('email', values.emailAddress);
                data.append('webpageLink', values.webpageLink);
                data.append('businessType', values.businessType);
                data.append('cuisine', JSON.stringify(values.cuisine));
                data.append('businessOffer', JSON.stringify(values.businessOffer));
                data.append('groupSize', values.groupSize);
                data.append('menuDetails', values.menuDetails);
                data.append('businessDescription', values.businessDescription);
                data.append('available', JSON.stringify(values.available));
                data.append('preimages', JSON.stringify(values.preimages));
                data.append('profilePicture', values.profilePicture);
                for (let i = 0; i < values.images.length; i++) {
                    data.append('images', values.images[i]);
                }
                const response = await axios.post(`${API_URL}/restaurants/admin/update`, data);
                toast.success(response.data.message)
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setLoadingupdation(false)
            } catch (error) {
                console.log(error);
                toast.error("oops try again later!")
                setLoadingupdation(false)
            }
        },
    });

    const availability = () => {
        formik.setFieldValue('validity', true);
        if (formik.values.days === "") {
            formik.setFieldTouched("days", true);
        }
        if (formik.values.hours.length === 0) {
            formik.setFieldTouched("hours", true);
        }

        if (formik.values.days !== "" && formik.values.hours.length > 0) {
            const newAvailability = {
                days: formik.values.days,
                hours: formik.values.hours
            };
            formik.setFieldValue('available', [...formik.values.available, newAvailability]);
            formik.setFieldValue('days', '');
            formik.setFieldValue('hours', []);
            formik.setFieldValue('validity', false);
        }
    };

    const availabledel = (indexToRemove) => {
        const newAvailable = formik.values.available.filter((item, index) => index !== indexToRemove);
        formik.setFieldValue('available', newAvailable);
    };

    const imagesdel = (indexToRemove) => {
        const newAvailable = formik.values.preimages.filter((item, index) => index !== indexToRemove);
        formik.setFieldValue('preimages', newAvailable);
    };





    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Update Restaurants" breadcrumbItem="Update Restaurants" />
                    {loading ?
                        <>
                            <center>
                                <div className="spinner-border mt-6" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </center>
                        </>
                        :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col xl={12}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Restaurant Basic Information</CardTitle>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="restaurantName">Restaurant Name</Label>
                                                            <Input
                                                                type="text"
                                                                name="restaurantName"
                                                                value={formik.values.restaurantName}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.restaurantName && formik.errors.restaurantName}
                                                            />
                                                            {formik.touched.restaurantName && formik.errors.restaurantName ? (
                                                                <FormFeedback>{formik.errors.restaurantName}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="streetAddress">Street Address</Label>
                                                            <Input
                                                                type="text"
                                                                name="streetAddress"
                                                                value={formik.values.streetAddress}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.streetAddress && formik.errors.streetAddress}
                                                            />
                                                            {formik.touched.streetAddress && formik.errors.streetAddress ? (
                                                                <FormFeedback>{formik.errors.streetAddress}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="postNumber">Post Number</Label>
                                                            <Input
                                                                type="text"
                                                                name="postNumber"
                                                                value={formik.values.postNumber}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.postNumber && formik.errors.postNumber}
                                                            />
                                                            {formik.touched.postNumber && formik.errors.postNumber ? (
                                                                <FormFeedback>{formik.errors.postNumber}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="city">City</Label>
                                                            <Input
                                                                type="text"
                                                                name="city"
                                                                value={formik.values.city}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.city && formik.errors.city}
                                                            />
                                                            {formik.touched.city && formik.errors.city ? (
                                                                <FormFeedback>{formik.errors.city}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="telephoneNumber">Telephone Number</Label>
                                                            <Input
                                                                type="text"
                                                                name="telephoneNumber"
                                                                value={formik.values.telephoneNumber}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.telephoneNumber && formik.errors.telephoneNumber}
                                                            />
                                                            {formik.touched.telephoneNumber && formik.errors.telephoneNumber ? (
                                                                <FormFeedback>{formik.errors.telephoneNumber}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailAddress">Email Address</Label>
                                                            <Input
                                                                type="email"
                                                                name="emailAddress"
                                                                value={formik.values.emailAddress}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.emailAddress && formik.errors.emailAddress}
                                                            />
                                                            {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                                                <FormFeedback>{formik.errors.emailAddress}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="webpageLink">Webpage Link</Label>
                                                            <Input
                                                                type="text"
                                                                name="webpageLink"
                                                                value={formik.values.webpageLink}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="businessType">Business Type</Label>
                                                            <Input
                                                                type="select"
                                                                name="businessType"
                                                                value={formik.values.businessType}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.businessType && formik.errors.businessType}
                                                            >
                                                                <option value="">Choose</option>
                                                                <option value="Restaurang">Restaurang</option>
                                                                <option value="Coffee and Confectionery">Coffee and Confectionery</option>
                                                            </Input>
                                                            {formik.touched.businessType && formik.errors.businessType ? (
                                                                <FormFeedback>{formik.errors.businessType}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {formik.values.type != "own" ?
                                            <>
                                                {/* Cuisine */}
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle className="mb-4">Approval Details</CardTitle>
                                                        <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="subject">Subject</Label>
                                                            <Input
                                                                type="text"
                                                                name="subject"
                                                                value={formik.values.subject}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                        </div>
                                                        </Col>
                                                        <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="message">Message</Label>
                                                            <Input
                                                                type="textarea"
                                                                name="message"
                                                                id="message"
                                                                value={formik.values.message}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </div>
                                                        </Col>
                                                    </CardBody>
                                                </Card>
                                            </>
                                            :
                                            <></>

                                        }

                                        {/* Cuisine */}
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Cuisine</CardTitle>
                                                <div className="mb-3">
                                                    <Select
                                                        isMulti
                                                        name="cuisine"
                                                        options={cuisines}
                                                        value={formik.values.cuisine}
                                                        onChange={(value) => formik.setFieldValue("cuisine", value)}
                                                        onBlur={formik.handleBlur}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {formik.touched.cuisine && formik.errors.cuisine ? (
                                                        <div className="invalid-feedback d-block">{formik.errors.cuisine}</div>
                                                    ) : null}
                                                </div>
                                            </CardBody>
                                        </Card>

                                        {/* Business Offer */}
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Business Offer</CardTitle>
                                                <div className="mb-3">
                                                    <Select
                                                        isMulti
                                                        name="businessOffer"
                                                        options={options}
                                                        value={formik.values.businessOffer}
                                                        onChange={(value) => formik.setFieldValue("businessOffer", value)}
                                                        onBlur={formik.handleBlur}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    {formik.touched.businessOffer && formik.errors.businessOffer ? (
                                                        <div className="invalid-feedback d-block">{formik.errors.businessOffer}</div>
                                                    ) : null}
                                                </div>
                                            </CardBody>
                                        </Card>

                                        {/* Availability */}
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Availability</CardTitle>
                                                <Row>
                                                    <Col md={5}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="days">Days</Label>
                                                            <Input
                                                                type="select"
                                                                name="days"
                                                                value={formik.values.days}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                invalid={formik.touched.days && formik.errors.days}
                                                            >
                                                                <option value="">Choose</option>
                                                                <option value="Monday">Monday</option>
                                                                <option value="Tuesday">Tuesday</option>
                                                                <option value="Wednesday">Wednesday</option>
                                                                <option value="Thursday">Thursday</option>
                                                                <option value="Friday">Friday</option>
                                                                <option value="Saturday">Saturday</option>
                                                                <option value="Sunday">Sunday</option>
                                                            </Input>
                                                            {formik.touched.days && formik.errors.days ? (
                                                                <FormFeedback>{formik.errors.days}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={5}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="hours">Time in Hours</Label>
                                                            <Select
                                                                isMulti
                                                                name="hours"
                                                                options={optionGroup}
                                                                value={formik.values.hours}
                                                                onChange={(value) => formik.setFieldValue("hours", value)}
                                                                onBlur={formik.handleBlur}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                            {formik.touched.hours && formik.errors.hours ? (
                                                                <div className="invalid-feedback d-block">{formik.errors.hours}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button onClick={() => availability()} type="button" style={{ marginTop: '25px' }} color="primary" outline>add</Button>
                                                    </Col>
                                                    {formik.values.available.length > 0 &&
                                                        <Col md={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Days</th>
                                                                            <th>Hours</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formik.values.available.map((item, index) => (
                                                                            <tr key={index} >
                                                                                <td>{item.days}</td>
                                                                                <td>
                                                                                    {item.hours.map((item, indexx) => (
                                                                                        <>
                                                                                            <span key={indexx} style={{ marginRight: '6px' }} >{item.label},</span>
                                                                                        </>
                                                                                    ))
                                                                                    }
                                                                                </td>
                                                                                <td><i onClick={() => availabledel(index)} style={{ fontSize: '20px', color: 'red', padding: '3px', cursor: 'pointer' }} className="bx bx-trash" ></i></td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        {/* Group Size */}
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Group Size</CardTitle>
                                                <div className="mb-3">
                                                    <Input
                                                        type="select"
                                                        name="groupSize"
                                                        value={formik.values.groupSize}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.groupSize && formik.errors.groupSize}
                                                    >
                                                        <option value="">Choose</option>
                                                        {[...Array(15).keys()].map((num) => (
                                                            <option key={num + 1} value={num + 1}>{num + 1}</option>
                                                        ))}
                                                        <option value="No Limit">No Limit</option>
                                                    </Input>
                                                    {formik.touched.groupSize && formik.errors.groupSize ? (
                                                        <FormFeedback>{formik.errors.groupSize}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </CardBody>
                                        </Card>

                                        {/* Other Information */}
                                        <Card>
                                            <CardBody>
                                                <CardTitle className="mb-4">Other Information</CardTitle>
                                                <Row>
                                                    <Col md={8}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="profilePicture">Restaurant Profile Picture (Single)</Label>
                                                            <Input
                                                                id="profilePicture"
                                                                name="profilePicture"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(event) => {
                                                                    formik.setFieldValue("profilePicture", event.currentTarget.files[0]);
                                                                }}
                                                                invalid={formik.touched.profilePicture && formik.errors.profilePicture}
                                                            />
                                                            {formik.errors.profilePicture && formik.touched.profilePicture ? (
                                                                <FormFeedback>{formik.errors.profilePicture}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="mb-3">
                                                            <img style={{ width: '100px' }} src={`${formik.values.preprofilePicture}`} ></img>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="images">Restaurant Cover Pictures (Multiple)</Label>
                                                            <Input
                                                                type="file"
                                                                name="images"
                                                                id="images"
                                                                onChange={(event) => formik.setFieldValue("images", event.currentTarget.files)}
                                                                multiple
                                                                accept="image/png, image/jpeg"
                                                            />
                                                        </div>
                                                    </Col>
                                                    {formik.values.preimages.length > 0 ?
                                                        <Col md={12}>
                                                            <div className="table-responsive">
                                                                <Table className="table mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Images</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {formik.values.preimages.map((item, index) => (
                                                                            <tr key={index} >
                                                                                <td><img key={index} style={{ width: '100px', padding: '5px' }} src={`${item}`} ></img></td>
                                                                                <td><i onClick={() => imagesdel(index)} style={{ fontSize: '20px', color: 'red', padding: '3px', cursor: 'pointer' }} className="bx bx-trash" ></i></td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Col>
                                                        :
                                                        ''
                                                    }
                                                    <br></br>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="menuDetails">Restaurant Menu Details</Label>
                                                            <Input
                                                                type="textarea"
                                                                name="menuDetails"
                                                                id="menuDetails"
                                                                value={formik.values.menuDetails}
                                                                onChange={formik.handleChange}
                                                                invalid={formik.touched.menuDetails && formik.errors.menuDetails}
                                                            />
                                                            {formik.touched.menuDetails && formik.errors.menuDetails ? (
                                                                <FormFeedback>{formik.errors.menuDetails}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="businessDescription">Brief Description of Your Business</Label>
                                                            <Input
                                                                type="textarea"
                                                                value={formik.values.businessDescription}
                                                                name="businessDescription"
                                                                id="businessDescription"
                                                                onChange={formik.handleChange}
                                                                invalid={formik.touched.businessDescription && formik.errors.businessDescription}
                                                            />
                                                            {formik.touched.businessDescription && formik.errors.businessDescription ? (
                                                                <FormFeedback>{formik.errors.businessDescription}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Button type="submit" disabled={loading} color="primary" outline>{loading ? 'loading..' : 'Submit'}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Update;
