import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import TableContainer from '../../components/Common/TableContainer';
import { Button, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { API_URL } from "components/Constant";
import axios from "axios";
import toast from "react-hot-toast";

const Index = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [delloading, setDelLoading] = useState(false)
    console.log(data);
    const datafetch = async () => {
        const response = await axios.get(`${API_URL}/user/admin/contact-us`);
        setData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        datafetch();
    }, [])


    const [deleteModal, setDeleteModal] = useState(false);
    const [deletedata, setdeleteData] = useState(null);

    const onClickDelete = (job) => {
        setdeleteData(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        setDelLoading(true);
        setDeleteModal(false);
    
        const deleteRequest = async () => {
          const formData = new FormData();
          formData.append('id', deletedata._id);
    
          const response = await axios.post(`${API_URL}/Contactss/admin/delete`, formData);
          return response.data;
        };

        toast.promise(
            deleteRequest(),
            {
              loading: 'Deleting...',
              success: 'Job deleted!',
              error: 'Could not delete',
            }
          ).then(() => {
            if (deletedata) {
              setData(data.filter(item => item._id !== deletedata._id));
            }
          }).catch((error) => {
            console.error("Error deleting job:", error);
          }).finally(() => {
            setDelLoading(false);
          });
        };

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Number ',
                accessorKey: 'number',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Subject ',
                accessorKey: 'subject',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Message ',
                accessorKey: 'message',
                enableColumnFilter: false,
                enableSorting: true,
            },
        ],
        []
    );

    // const data = [
    //     {
    //         name: "Anaya Contactss",
    //         Businesstype: "Restaurang",
    //         Emailaddress: "Contactss@gmail.com",
    //     },
    //     {
    //         name: "Fazal Haq dera",
    //         Businesstype: "Restaurang",
    //         Emailaddress: "dera@gmail.com",
    //     },
    // ];



    // Meta title
    document.title = "Grandsave Manage Contactss";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Manage Contacts" breadcrumbItem="Manage Contacts" />
                {loading ?
                    <>
                        <center>
                            <div className="spinner-border mt-6" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </center>
                    </>
                    :
                    <>
                        {data.length > 0 ?
                            <>
                                <TableContainer
                                    columns={columns}
                                    data={data || []}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    SearchPlaceholder="search records..."
                                    pagination="pagination"
                                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                />
                                <DeleteModal
                                    show={deleteModal}
                                    onDeleteClick={handleDeletejob}
                                    onCloseClick={() => setDeleteModal(false)}
                                    msg="Are you sure you want to permanently remove the Contacts!"
                                />
                            </>
                            :
                            <>
                                <center>
                                    <div style={{marginTop:'10%'}} >
                                        <img src="/question-mark.png" style={{width:'100px'}} ></img>
                                        No Contactss exits in our records
                                    </div>
                                </center>
                            </>
                        }

                    </>
                }
            </div>
        </div>
    );
}

export default Index;
