import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import TableContainer from '../../components/Common/TableContainer';
import { Button, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { API_URL } from "components/Constant";
import axios from "axios";
import toast from "react-hot-toast";

const Index = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [delloading, setDelLoading] = useState(false)
    console.log(data);
    const datafetch = async () => {
        const response = await axios.post(`${API_URL}/restaurants/admin/fetchall/own`);
        setData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        datafetch();
    }, [])


    const [deleteModal, setDeleteModal] = useState(false);
    const [deletedata, setdeleteData] = useState(null);

    const onClickDelete = (job) => {
        setdeleteData(job);
        setDeleteModal(true);
    };

    const handleDeletejob = async () => {
        setDelLoading(true);
        setDeleteModal(false);
    
        const deleteRequest = async () => {
          const formData = new FormData();
          formData.append('id', deletedata._id);
    
          const response = await axios.post(`${API_URL}/restaurants/admin/delete`, formData);
          return response.data;
        };

        toast.promise(
            deleteRequest(),
            {
              loading: 'Deleting...',
              success: 'Job deleted!',
              error: 'Could not delete',
            }
          ).then(() => {
            if (deletedata) {
              setData(data.filter(item => item._id !== deletedata._id));
            }
          }).catch((error) => {
            console.error("Error deleting job:", error);
          }).finally(() => {
            setDelLoading(false);
          });
        };

    const columns = useMemo(
        () => [
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Business type',
                accessorKey: 'businesstype',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'E mail address ',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to={`/restaurant/update/${cellProps.row.original._id}`}
                                    className="btn btn-sm btn-soft-info"
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i style={{ fontSize: 'medium' }} className="bx bx-edit" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i style={{ fontSize: 'medium' }} className="bx bx-trash" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    // const data = [
    //     {
    //         name: "Anaya Restaurants",
    //         Businesstype: "Restaurang",
    //         Emailaddress: "Restaurants@gmail.com",
    //     },
    //     {
    //         name: "Fazal Haq dera",
    //         Businesstype: "Restaurang",
    //         Emailaddress: "dera@gmail.com",
    //     },
    // ];



    // Meta title
    document.title = "Grandsave Manage Restaurants";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Manage Restaurant" breadcrumbItem="Manage Restaurant" />
                <Link to="/restaurant/add"><Button style={{ float: 'right' }} color="primary" outline>Add Restaurant</Button></Link>
                {loading ?
                    <>
                        <center>
                            <div className="spinner-border mt-6" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </center>
                    </>
                    :
                    <>
                        {data.length > 0 ?
                            <>
                                <TableContainer
                                    columns={columns}
                                    data={data || []}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    SearchPlaceholder="search records..."
                                    pagination="pagination"
                                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                />
                                <DeleteModal
                                    show={deleteModal}
                                    onDeleteClick={handleDeletejob}
                                    onCloseClick={() => setDeleteModal(false)}
                                    msg="Are you sure you want to permanently remove the Restaurant!"
                                />
                            </>
                            :
                            <>
                                <center>
                                    <div style={{marginTop:'10%'}} >
                                        <img src="/question-mark.png" style={{width:'100px'}} ></img>
                                        No Restaurants exits in our records
                                    </div>
                                </center>
                            </>
                        }

                    </>
                }
            </div>
        </div>
    );
}

export default Index;
