import React, { useEffect, useMemo, useState } from "react";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import TableContainer from '../../components/Common/TableContainer';
import { Button, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { API_URL } from "components/Constant";
import axios from "axios";
import toast from "react-hot-toast";
import { useParams } from 'react-router-dom';

const History = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const { id } = useParams();

    console.log(data);
    const datafetch = async () => {
        const response = await axios.post(`${API_URL}/user/admin/history`, { id });
        setData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        datafetch();
    }, [id])



    const columns = useMemo(
        () => [
            {
                header: 'Title',
                accessorKey: 'title',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Type',
                accessorKey: 'type',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Price',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => (`$${cellProps.row.original.price}`)
            },
            {
                header: 'Price',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <>
                        {cellProps.row.original.status==1 ? 
                        <> <button className="btn btn-success" >Active</button> </>
                        :
                        <> <button className="btn btn-danger" >Expired</button> </>
                        }
                        </>
                    )
                }
            },

        ],
        []
    );

    // Meta title
    document.title = "Grandsave User subscription History";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="User subscription History" breadcrumbItem="User subscription History" />
                {loading ?
                    <>
                        <center>
                            <div className="spinner-border mt-6" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </center>
                    </>
                    :
                    <>
                    <h2>{data[0].user_id.name}</h2>
                        {data.length > 0 ?
                            <>
                                <TableContainer
                                    columns={columns}
                                    data={data || []}
                                    isGlobalFilter={true}
                                    isPagination={true}
                                    SearchPlaceholder="search records..."
                                    pagination="pagination"
                                    paginationWrapper='dataTables_paginate paging_simple_numbers'
                                    tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                />
                            </>
                            :
                            <>
                                <center>
                                    <div style={{ marginTop: '10%' }} >
                                        <img src="/question-mark.png" style={{ width: '100px' }} ></img>
                                        No User exits in our records
                                    </div>
                                </center>
                            </>
                        }

                    </>
                }
            </div>
        </div>
    );
}

export default History;
