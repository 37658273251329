import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { API_URL } from "components/Constant";
import axios from "axios";



const Dashboard = props => {

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [delloading, setDelLoading] = useState(false)
  console.log(data);
  const datafetch = async () => {
    const response = await axios.post(`${API_URL}/restaurants/admin/dashboard`);
    setData(response.data)
    setLoading(false)
  }

  useEffect(() => {
    datafetch();
  }, [])


  //meta title
  document.title = "Dashboard Grandsave";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem="Dashboard"
          />
          {loading ?
            <>
              <center>
                <div className="spinner-border mt-6" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </center>
            </>
            :
            <>
              <Row>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Restaurants Approval Pending
                          </p>
                          <h4 className="mb-0">{data.RestaurantsTotalApproval}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-restaurant" style={{ fontSize: '25px' }} ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Restaurants Listed
                          </p>
                          <h4 className="mb-0">{data.RestaurantsTotalListed}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-restaurant" style={{ fontSize: '25px' }} ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Contactus Total
                          </p>
                          <h4 className="mb-0">{data.ContactusTotal}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-message-alt-dots" style={{ fontSize: '25px' }} ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4" >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            UsersTotal
                          </p>
                          <h4 className="mb-0">{data.UsersTotal}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx bx-user-voice" style={{ fontSize: '25px' }} ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }

        </Container>
      </div>

    </React.Fragment>
  );
};


export default Dashboard;
