import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// restaurant
import ManageIndex from "pages/Restaurant/Index";
import RestaurantAdd from "pages/Restaurant/Add";
import RestaurantApprovals from "pages/Restaurant/Approvals";
import RestaurantUpdate from "pages/Restaurant/Update";

// users
import UserIndex from "pages/User/Index";
import ContactIndex from "pages/Contact/Index";
import UserHistory from "pages/User/History";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  // restaurant
  { path: "/restaurant", component: <ManageIndex /> },
  { path: "/restaurant/add", component: <RestaurantAdd /> },
  { path: "/restaurant/update/:id", component: <RestaurantUpdate /> },
  { path: "/restaurant/approval", component: <RestaurantApprovals /> },


  // users
  { path: "/users", component: <UserIndex /> },
  { path: "/users/history/:id", component: <UserHistory /> },


  // contact
  { path: "/contact", component: <ContactIndex /> },





  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
];

export { authProtectedRoutes, publicRoutes };
